import React from "react"
import { useLocation } from "@reach/router"
import { useIntl } from "gatsby-plugin-intl"
import CookieConsent from "react-cookie-consent"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

import "./footer.scss"

const Footer = () => {
  let intl = useIntl()
  let location = useLocation()

  return (
    <>
      <footer className="footer"></footer>
      <CookieConsent
        disableStyles={true}
        enableDeclineButton
        buttonText={intl.formatMessage({ id: "cookieConsentAccept" })}
        declineButtonText={intl.formatMessage({ id: "cookieConsentDecline" })}
        buttonClasses="CookieConsentAcceptButton"
        declineButtonClasses="CookieConsentDeclineButton"
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={() => {
          initializeAndTrack(location)
        }}
      >
        <p className="CookieConsentMessage">
          {intl.formatMessage({ id: "cookieConsent" })}
        </p>
      </CookieConsent>
    </>
  )
}

export default Footer
